<template>
    <div v-if="loading" class="loading-container row justify-center">
        <q-linear-progress indeterminate class="q-mb-md" />
    </div>
    <router-view v-else />
</template>

<script>
import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "vuex";

export default defineComponent({
    setup() {
        const store = useStore();
        const loading = computed(
            () => store.state.palveluyksikotLoading || store.state.organisationsLoading
        );
        const fetchPalveluyksikot = () => store.dispatch("fetchPalveluyksikot");
        const fetchOrganisations = (valvoja) => store.dispatch("fetchOrganisations", valvoja);
        onMounted(fetchOrganisations(true));
        onMounted(fetchPalveluyksikot);

        return { loading };
    },
});
</script>
